import React from 'react';
import { OnboardingStep } from '~/widgets/PageOnboarding';

import { getStepCN } from './portfolio.onboarding.helpers';

export const getMixedPortfolioOnboarding = (
  sectionWidth: number,
  riskLevelLabel: React.ReactNode,
  userTopics: number[],
): OnboardingStep[] => [
  {
    id: 'breakdown',
    className: getStepCN('breakdown'),
    position: 'bottom' as const,
    content: (
      <>
        Your portfolio consists of S&P 500 stocks and reputable bond-ETFs from e.g. Blackrock & Vanguard.
        <br />
        <br />
        {riskLevelLabel}
      </>
    ),
  },
  {
    id: 'holdings',
    className: getStepCN('holdings'),
    position: 'top-middle-aligned' as const,
    sectionWidth,
    content: (
      <>
        These are the individual holdings that make up your portfolio.{' '}
        {userTopics.length ? (
          <>
            It includes holdings in line with your selected investment areas as well as holdings from other industries
            to ensure portfolio diversification.
          </>
        ) : (
          <>It includes holdings from a wide range of industries to ensure portfolio diversification.</>
        )}
      </>
    ),
  },
  {
    id: 'addHoldings',
    className: getStepCN('addHoldings'),
    position: 'bottom-right-aligned' as const,
    sectionWidth,
    fillDefaultBackground: true,
    highlightBorderRadius: 'm' as const,
    content: (
      <>
        While your portfolio is attuned to your risk tolerance and horizon, you retain the flexibility to add or remove
        holdings as desired. <b>Note</b>: changes affect the risk profile and performance of your portfolio.
      </>
    ),
  },
  {
    id: 'equityValue',
    className: getStepCN('equityValue'),
    position: 'bottom-left-aligned' as const,
    content: <>When you fund your account, all your portfolio holdings will be bought, equally distributed.</>,
  },
  {
    id: 'info',
    className: getStepCN('info'),
    position: 'bottom-right-aligned' as const,
    sectionWidth,
    fillDefaultBackground: true,
    highlightBorderRadius: 'xl' as const,
    content: (
      <>To learn more about your portfolio, including how we selected your holdings and costs, click the info button.</>
    ),
  },
];
