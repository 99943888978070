import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import * as profileSelectors from '@store/profile/selectors';

import { FooterDisclaimer } from '~/widgets/FooterDisclaimer';

import * as S from './Page.styled';

type Props = {
  title: React.ReactNode;
  className?: string;
  desktopContentWidth?: number;
  showFundBtn?: boolean;
  hideFooterDisclaimer?: boolean;
};

export const PageLayout: FC<PropsWithChildren<Props>> = (props) => {
  const profile = useSelector(profileSelectors.selectProfile);
  const hasAlpacaAccount = profile?.has_alpaca_account;

  return (
    <S.Container className={props.className}>
      {props.title && <S.PageHeader title={props.title} showFundBtn={props.showFundBtn || !hasAlpacaAccount} />}

      <S.ContentContainer desktopWidth$={props.desktopContentWidth}>{props.children}</S.ContentContainer>

      {!props.hideFooterDisclaimer && (
        <S.FooterContainer>
          <FooterDisclaimer />
        </S.FooterContainer>
      )}
    </S.Container>
  );
};
