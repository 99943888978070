import styled, { css } from 'styled-components';

import TopHeader from '@components/TopHeader/TopHeader';
import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';
import { Card } from '~/ui/kit/organisms/Card';

export const Container = styled('div')``;

export const PageHeader = styled(TopHeader)`
  margin-bottom: ${gaps.m}px;
`;

export const ContentContainer = styled.div<{ desktopWidth$?: number }>`
  width: 100%;

  ${({ desktopWidth$ }) =>
    desktopWidth$ &&
    css`
      ${media.md`
      max-width: ${desktopWidth$}px;
    `}
    `}
`;

export const FooterContainer = styled(Card)`
  width: 100%;
  margin-top: 30px;

  background-color: var(--eko-primary-color-light-6);
  color: var(--eko-footer-disclaimer-color, var(--eko-third-color));
`;
