import React, { useMemo } from 'react';

import { checkDeviceSize, useAppMediaContext } from '~/contexts/AppMedia';
import { OnboardingStep } from '~/widgets/PageOnboarding';
import { getOnboardingStepCNFactory } from '~/shared/onboardings';

export const getStepCN = getOnboardingStepCNFactory('portfolioControls');

type OnboardingStepsResult = OnboardingStep[];

export const PORTFOLIO_SWITCHER_CN = getStepCN('selector');
export const useGetPortfolioSwitcherOnboarding = (): OnboardingStep[] => {
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.md(deviceSize);

  return useMemo((): OnboardingStepsResult => {
    return [
      {
        id: 'selector',
        className: PORTFOLIO_SWITCHER_CN,
        position: 'bottom-left-aligned' as const,
        sectionWidth: 350,
        additionalLeftOffset: isMobile ? 16 : 0,
        content: <>You can create new portfolios, and switch between existing portfolios here.</>,
      },
    ];
  }, [isMobile]);
};
